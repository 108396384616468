import { MyProfileComponent } from 'src/app/my-profile/my-profile.component';
import { HomeComponent } from '../../home';
import { AppGuard } from '../../shared';
const ɵ0 = () => import("./../../group-rights/group-rights.module.ngfactory").then(m => m.GroupRightsModuleNgFactory), ɵ1 = () => import("./../../user-rights/user-rights.module.ngfactory").then(m => m.UserRightsModuleNgFactory), ɵ2 = () => import("./../../tenant-management/tenant-management.module.ngfactory").then(m => m.TenantManagementModuleNgFactory), ɵ3 = () => import("./../../player-management/player-management.module.ngfactory").then(m => m.PlayerManagementModuleNgFactory), ɵ4 = () => import("./../../reports/reports.module.ngfactory").then(m => m.ReportsModuleNgFactory), ɵ5 = () => import("./../../financial/financial.module.ngfactory").then(m => m.FinancialModuleNgFactory), ɵ6 = () => import("./../../wallet/wallet.module.ngfactory").then(m => m.WalletModuleNgFactory), ɵ7 = () => import("./../../draw-processing/draw-processing.module.ngfactory").then(m => m.DrawProcessingModuleNgFactory), ɵ8 = () => import("./../../ticket-management/ticket-management.module.ngfactory").then(m => m.TicketManagementModuleNgFactory), ɵ9 = () => import("./../../retail-agent/retail-agent.module.ngfactory").then(m => m.RetailAgentModuleNgFactory);
export const SECURE_ROUTES = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AppGuard]
    },
    {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AppGuard]
    },
    {
        path: 'group-management',
        loadChildren: ɵ0,
        canActivate: [AppGuard]
    },
    {
        path: 'user-management',
        loadChildren: ɵ1,
        canActivate: [AppGuard]
    },
    {
        path: 'tenant-management',
        loadChildren: ɵ2,
        canActivate: [AppGuard]
    },
    {
        path: 'player-management',
        loadChildren: ɵ3,
        canActivate: [AppGuard]
    },
    {
        path: 'reports',
        loadChildren: ɵ4,
        canActivate: [AppGuard]
    },
    {
        path: 'financial',
        loadChildren: ɵ5,
        canActivate: [AppGuard]
    },
    {
        path: 'wallet',
        loadChildren: ɵ6,
        canActivate: [AppGuard]
    },
    {
        path: 'draw-processing',
        loadChildren: ɵ7,
        canActivate: [AppGuard]
    },
    {
        path: 'ticket-management',
        loadChildren: ɵ8,
        canActivate: [AppGuard]
    },
    {
        path: 'retail-agents',
        loadChildren: ɵ9,
        canActivate: [AppGuard]
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
